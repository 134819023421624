import axios from "axios";

export default {
  data() {
    return {
      // host: 'http://192.168.11.3:9998',
      host: 'https://d1m3kfg759ocgz.cloudfront.net',
      statusOptions: [
        // {value: undefined, label: "フィルター"},
        {value: 0, label: "全て", color: "black", variant: "outline"},
        {value: 1, label: "募集", color: "success", variant: "outline"},
        {value: 2, label: "提案", color: "info", variant: "outline"},
        {value: 3, label: "承認待ち", color: "warning", variant: "outline"},
        {value: 4, label: "交渉", color: "primary", variant: "outline"},
        {value: 5, label: "仕事中", color: "danger", variant: "outline"},
        {value: 9, label: "完了", color: "secondary", variant: "outline"},
        {value: -1, label: "削除", color: "dark", variant: "outline"},
      ],
      orderOptions: [
        {value: 1, label: "新しい順"},
        {value: 2, label: "古い順"},
        {value: 3, label: "依頼者"},
        {value: 4, label: "受注者"},
      ],
      toasts: [],
    }
  },
  computed: {
    toastConfig() {
      return {
        autohide: 1000,
        closeButton: true,
        fade: true,
        position: 'top-right',
        header: '',
        color: 'dark',
        msg: '',
      }
    },
    toasters () {
      return this.toasts.reduce((toasters, toast) => {
        toasters[toast.position] = toasters[toast.position] || []
        toasters[toast.position].push(toast)
        return toasters
      }, {})
    }
  },
  filters: {
    nfRound: function (string) {//, precision) {
      //precision で指定された桁数で round とするというPHPの
      //round みたいなのを作りたいんだけど今はまだ実装してません。
      //有無を言わさず小数点２桁で返します。
      if (string === '') return 0;
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")) * 100) / 100;
    },
    nfNum: function (string) {
      if (string === '') return 0;
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")));
    },
    nfYen: function (string) {
      if (string === '') return 0 + '円';
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, ""))).toLocaleString() + '円';
    },
    nfDoller: function (string) {
      if (string === '') return '$' + 0.00 + '-';
      return '$' + Number(Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")) * 100) / 100).toLocaleString() + '-';
    },
    nfPer: function (string) {
      if (string === '') return 0 + '%';
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")) * 10000) / 100 + '%';
    },
    nfQty: function (string) {
      if (string === '') return 0 + '個';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '個';
    },
    nfSha: function (string) {
      if (string === '') return 0 + '社';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '社';
    },
    nfDay: function (string) {
      if (string === '') return 0 + '日';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '日';
    },
  },
  methods: {
    addToast (header, msg, time_to_hide = 1000) {
      this.toastConfig['msg'] = msg
      this.toastConfig['header'] = header
      this.toastConfig['autohide'] = time_to_hide
      this.toasts.push(this.toastConfig)
    },
    //指定された数が入った配列を返す。
    getNumArrayByString(start_number, n) {
      let array = [];
      for (let i = start_number; i <= n; i++) {
        array.push(String(i));
      }
      return array;
    },

    //// 99,999円の形で返す関数
    nfNum(string) {
      if (string === '') return 0;
      return Math.floor(Number(String(string).replace(/[^-^0-9.-]/g, "")));
    },
    nfYen(string) {
      if (string === '') return 0 + '円';
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, ""))).toLocaleString() + '円';
    },
    nfDoller(string) {
      if (string === '') return '$' + 0.00 + '-';
      return '$' + Number(Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")) * 100) / 100).toLocaleString() + '-';
    },
    nfPer(string) {
      if (string === '') return 0 + '%';
      return Math.floor(Number(String(string).replace(/[^-^0-9.]/g, "")) * 10000) / 100 + '%';
    },
    nfQty(string) {
      if (string === '') return 0 + '個';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '個';
    },
    nfSha(string) {
      if (string === '') return 0 + '社';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '社';
    },
    nfDay(string) {
      if (string === '') return 0 + '日';
      return Number(String(string).replace(/[^-^0-9.]/g, "")) + '日';
    },
    ////////////////////////////////////////////////////////////
    //// ここからAPI関連のメソッド
    ////////////////////////////////////////////////////////////
    async http_client(method, url, data){
      // if (!this.$store.state.token){
      //   window.alert("ログインしてください！！")
      //   return false
      // }
      const token = this.$store.state.token
      const token_w_schema = "Bearer " + token
      const headers = {
        'Authorization': token_w_schema
      }
      const options = {
        method,
        url,
        headers,
        data,
        timeout: 60000
      };
      return await axios(options)
        .then(function (response) {
          return response.data
        }).catch(error => {
          console.log("エラーです。", error)
          this._logout()
        }, this)
    },
    //ログアウト
    async _logout(){
      await this.$store.commit('set', ['token', ""])
      await this.$store.commit('set', ['user_id', 0])
      await this.$store.commit('set', ['family_id', 0])
      await this.$store.commit('set', ['nickname', ""])
      await this.$store.commit('set', ['image_path', ""])
      await this.$store.commit('set', ['username', ""])
      await this.$store.commit('set', ['mail', ""])
      await this.$store.commit('set', ['first_name', ""])
      await this.$store.commit('set', ['last_name', ""])
      await this.$store.commit('set', ['is_admin', false])
      await this.$store.commit('set', ['your_family', []])
      await this.$store.commit('set', ['job_items', []])
      await this.$store.commit('set', ['status_options', []])
      await this.$store.commit('set', ['job_categories', []])
      // await this.$store.commit('set', ['notifications', ''])

      // window.alert("ログアウトしました。")
      await this.$router.push({path: '/auth/login'})
    },
    //ログイン
    async _login(username, password){
      const data = `username=${username}&password=${password}`
      const url = this.host + '/v1/auth/login/'
      const response = await this.http_client("POST", url, data)
      if(response === false){
        window.alert("なにか違います。。m(_ _)m")
        return
      }
      await this.$store.commit('set', ['token', response["access_token"]])
      //ユーザ情報を取得します。
      await this._get_current_user()
      //カテゴリ情報を取得します。
      await this._gets_categories()
      //家族情報を取得します。
      await this._gets_family()
      await this.$router.push({path: '/'})
      //通知を取得します。
      await this._get_notifications()
    },
    async _gets_categories(){
      const url = this.host + '/v1/categories/'
      const response = await this.http_client("GET", url, {})
      if(typeof response === 'object'){
        await this.$store.commit('set', ['job_categories', response])
      }
    },
    async _gets_family(){
      const url = this.host + `/v1/users/get_members/?family_id=${this.$store.state.family_id}`
      const response = await this.http_client("GET", url, {})
      if(typeof response === 'object'){
        response.map(item => {
          item['show_picture_sw'] = false
        })
        await this.$store.commit('set', ['your_family', response])
      }
    },
    async _get_current_user(){
      const url = this.host + '/v1/users/get_current_user/'
      const response = await this.http_client("GET", url, {})
      await this._set_user_info(response)
      return response
    },
    async _set_user_info(response){
      await this.$store.commit('set', ['user_id', Number(response["id"])])
      await this.$store.commit('set', ['family_id', Number(response["family_id"])])
      await this.$store.commit('set', ['nickname', response["nickname"]])
      await this.$store.commit('set', ['username', response["username"]])
      await this.$store.commit('set', ['mail', response["mail"]])
      await this.$store.commit('set', ['first_name', response["first_name"]])
      await this.$store.commit('set', ['last_name', response["last_name"]])
      await this.$store.commit('set', ['is_admin', response["is_admin"]])
      await this.$store.commit('set', ['image_path', "https://s3.ap-northeast-1.amazonaws.com/src.img/"])
    },
    //store に入ってるお仕事一覧からお仕事に含まれるリンクを取得してOGP画像を取得します。
    //promise モードでバックグラウンドで動かします。
    _getOgpFromStoreItems(){
      if(this.$store.state.job_items === 0) return
      const jobs_w_link = this.$store.state.job_items.filter(job => job.links.length > 0)
      jobs_w_link.map(job => {
        job.links.map(async link => {
          link.ogp = await this.__get_ogp_from_api(link["href"])
        }, this)
      }, this)
    },
    async __get_ogp_from_api(url){
      const full_url = this.host + `/v1/ext/get_ogp/?url=${url}`
      const response = await this.http_client("GET", full_url, {})
      if(!Array.isArray(response)) return false
      const count = response.filter(item => item.key === "image")
      if(count.length > 0){
        return response
      }
      return false
    },
    //お仕事一覧の取得
    async _getItems(data) {
      const response = await this.__getItems(data)
      await this.$store.commit('get_job_items', [])
      await this.$store.commit('get_job_items', response)
    },
    //追加でお仕事を読み込む舌矢印のボタンを押されたときに呼ばれるメソッド
    async _addItems(data){
      const response = await this.__getItems(data)
      await this.$store.commit('add_job_items', response)
    },
    //単体でのお仕事の取得
    async _getItem(item_id){
      const param = {id: item_id, family_id: this.$store.state.family_id}
      const item = await this.http_client("POST", this.host + '/v1/jobs/get/', param)
      item['offers_sw'] = false
      item['show_favorites_sw'] = false
      item['offers'].map(offer => {
        offer['show_favorites_sw'] = false
      })
      item["start_date"] = this.getShortDate(item["start_date"], "JST", 8)
      item["end_date"] = this.getShortDate(item["end_date"], "JST", 8)
      item["finish_date"] = this.getShortDate(item["finish_date"], "JST", 8)
      return item
    },
    //storeにあるitemをspliceします。
    _spliceItem(index, item){
      this.$store.commit('splice_job_item', [index, item])
    },
    async _getTemplates(limit, offset){
      const params = `?fid=${this.$store.state.family_id}&limit=${limit}&offset=${offset}`
      const method = "GET"
      const url = this.host + '/v1/jobs/gets/' + params
      const items = await this.http_client(method, url, {})
      return items.map(item => ({
        id: item.id,
        created: item.created,
        client_id: item.client_id,
        title: item.title,
        job_detail: item.job_detail,
        price: item.price,
        category_id: item.category_id,
        links: item.links,
        active: false,
      }))
    },
    async __getItems(data){
      const method = "POST"
      const url = this.host + '/v1/jobs/gets/'
      const response = await this.http_client(method, url, data)
      if(typeof response === 'object'){
        response.map(function(item) {
          item['offers_sw'] = false
          item['show_favorites_sw'] = false
          item['offers'].map(offer => {
            offer['show_favorites_sw'] = false
          })
          item["start_date"] = this.getShortDate(item["start_date"], "JST", 8)
          item["end_date"] = this.getShortDate(item["end_date"], "JST", 8)
          item["finish_date"] = this.getShortDate(item["finish_date"], "JST", 8)
        }, this)
        return response
      }
      return []
    },
    //お仕事の作成
    async _createItem(item){
      item["client_id"] = this.$store.state.user_id
      item["worker_id"] = 0
      item["category_id"] = Number(item["category_id"])
      item["price"] = Number(item["price"])
      item["start_date"] = item["start_date"] + 'T00:00:00'
      item["end_date"] = item["end_date"] + 'T00:00:00'
      item["finish_date"] = item["finish_date"] + 'T00:00:00'
      const url = this.host + '/v1/jobs/create/'
      return await this.http_client("POST", url, item)
    },
    //ユーザ情報の更新
    async _updateUser(item){
      const url = this.host + '/v1/users/update/'
      const response = await this.http_client("POST", url, item)
      await this._set_user_info(response)
      return response
    },
    //お仕事の更新
    async _updateItem(item){
      const url = this.host + '/v1/jobs/update/'
      item['family_id'] = this.$store.state.family_id
      item['client_id'] = this.$store.state.user_id

      if("start_date" in item){
        item["start_date"] = item["start_date"] + 'T00:00:00'
      }
      if("end_date" in item){
        item["end_date"] = item["end_date"] + 'T00:00:00'
      }
      if("finish_date" in item){
        item["finish_date"] = item["finish_date"] + 'T00:00:00'
      }
      return await this.http_client("POST", url, item)
    },
    //オファーの登録
    async _createOffer(item){
      item["worker_id"] = Number(item["worker_id"])
      item["offer_type"] = Number(item["offer_type"])
      const url = this.host + '/v1/offers/create/'
      return await this.http_client("POST", url, item)
    },
    //オファーの更新
    async _updateOffer(item){
      const url = this.host + '/v1/offers/update/'
      return await this.http_client("POST", url, item)
    },
    //アクティビティログを取得
    async _get_activity_logs(user_id){
      const url = this.host + '/v1/activity_log/'
      const data = {user_id: user_id}
      return await this.http_client("POST", url, data)
    },

    //いいねの登録
    async _createFavorite(item){
      item["user_id"] = Number(item["user_id"])
      item["job_id"] = Number(item["job_id"])
      item["family_id"] = Number(item["family_id"])
      const url = this.host + '/v1/favorites/create/'
      return await this.http_client("POST", url, item)
    },
    //いいねの更新
    async _updateFavorite(item){
      const url = this.host + '/v1/favorites/update/'
      return await this.http_client("POST", url, item)
    },
    //画像のアップロード
    async _imageUpload(item){
      const url = this.host + '/v1/img/upload/'
      return await this.http_client("POST", url, item)
    },
    //画像の更新
    async _imageUpdate(item){
      item['family_id'] = this.$store.state.family_id
      const url = this.host + '/v1/img/update/'
      return await this.http_client("POST", url, item)
    },
    //お仕事画像URLの取得
    _get_job_image(job_id, user_id, file_name){
      const family_path = `${this.$store.state.image_path}${this.$store.state.family_code}`
      return `${family_path}/${user_id}/${job_id}/${file_name}`
    },
    //アバター画像の取得
    _get_avatar(value){
      //この関数は value で指定された user_id の人のアバター画像パスを返します。
      //アバター画像パスは、家族毎に割り当てられる画像パスに
      //this.$state.store.member_info にあるメンバごとのアバターファイル名を
      //追加して作ります。
      //ファイル名: this.$state.store.member_info[x:id=user_id].avatar
      //ファイルフルパス: https://s3url/${family_code}/${user_id}/0/ファイル名
      const list_file_name = this.$store.state.your_family.filter(member => member.id === value)
      const family_path = `${this.$store.state.image_path}${this.$store.state.family_code}`
      if(list_file_name.length === 0){
        return `${this.$store.state.image_path}no_image.jpg`
      }
      return `${family_path}/${value}/0/${list_file_name[0]["avatar"]}`
    },
    //ニックネームの取得
    _get_nickname(value){
      const nickname = this.$store.state.your_family.filter(item => item.id === value)
      if(nickname.length === 0){
        return '不明'
      }
      return nickname[0].nickname
    },
    //ステータスの取得
    _get_status_color(value){
      const _value = this._get_status(value)
      return _value['color']
    },
    _get_status_text(value){
      const _value = this._get_status(value)
      return _value['label']
    },
    _get_status(value){
      const _status = this.statusOptions.filter(item => item['value'] === value)
      if(_status.length === 0){
        return {color: 'light', label: 'その他'}
      }
      return _status[0]
    },
    //設定の保存
    async _update_settings(item_json){
      const url = this.host + '/v1/setting/update/'
      return await this.http_client("POST", url, item_json)
    },
    //通知の取得
    async _get_notifications(){
      const url = this.host + '/v1/notification/'
      const response = await this.http_client("GET", url, {})
      await this.$store.commit('set', ['notifications', response])
    },
    ////////////////////////////////////////////////////////////
    //// ここまでAPI関連のメソッド
    ////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////
    //// ここから日付操作関連のメソッド
    //// https://proengineer.internous.co.jp/content/columnfeature/8417
    ////////////////////////////////////////////////////////////
    _getObjDate(str_date = null) {
      if (str_date === null) {
        return new Date();
      } else if (typeof (str_date) == "string") {
        const dt = str_date.replace(/T/, " ");
        return new Date(dt.replace(/-/g, "/"))
      } else if (typeof (str_date) == "object") {
        return str_date
      }
    },
    getDate(str_date = null) {
      //今日の日付を 2020-08-01 というフォーマットで返します。
      const obj_date = this._getObjDate(str_date)

      const year = obj_date.getFullYear();
      let month = (obj_date.getMonth() + 1);
      let date = obj_date.getDate();

      if (month < 10) {
        month = ('0' + (month)).slice(-2);
      }
      if (date < 10) {
        date = ('0' + (date)).slice(-2);
      }

      return year + '-' + month + '-' + date;
    },
    getShortDate(str_date = null, tz = 'JST', date_format = 1) {
      //date_format:1 = 2021.8.1(日) h:m というフォーマットで返します。
      //date_format:2 = 2021.8.1 というフォーマットで返します。
      //date_format:3 = 20.8.1 というフォーマットで返します。
      //date_format:4 = 20年8月1日(月) というフォーマットで返します。
      //date_format:5 = 2021-11-16T16:53 というフォーマットで返します。
      //date_format:6 = 20年8月1日(月)15:15 というフォーマットで返します。
      //date_format:7 = 8月1日 15:15 というフォーマットで返します。
      //date_format:8 = 2021-11-16 というフォーマットで返します。
      //date_format:9 = 12月17日(金) というフォーマットで返します。
      const obj_date = this._getObjDate(str_date)
      //ここでタイムゾーンをJSTに変換する
      if (tz === 'UTC') {
        obj_date.setHours(obj_date.getHours() + 9)
      }
      const year = obj_date.getFullYear();
      const month = (obj_date.getMonth() + 1);
      let date = obj_date.getDate();
      if (date < 10){
        date = ('0' + (date)).slice(-2);
      }
      const _day = obj_date.getDay();
      const hh = ("0" + obj_date.getHours()).slice(-2);
      let mm = obj_date.getMinutes();
      if (mm < 10) {
        mm = ('0' + (mm)).slice(-2);
      }
      let ss = obj_date.getSeconds();
      if (ss < 10) {
        ss = ('0' + (ss)).slice(-2);
      }
      const day = this.getDay(_day)

      if (date_format === 1) {
        return year + '.' + month + '.' + date + '(' + day + ')' + hh + ':' + mm;
      } else if (date_format === 2) {
        return year + '.' + month + '.' + date;
      } else if (date_format === 3) {
        return ('0' + (year)).slice(-2) + '.' + month + '.' + date;
      } else if (date_format === 4) {
        return ('0' + (year)).slice(-2) + '年' + month + '月' + date + '日(' + day + ')';
      } else if (date_format === 5) {
        return (year) + '-' + month + '-' + date + 'T' + hh + ':' + mm + ":" + ss;
      } else if (date_format === 6) {
        return ('0' + (year)).slice(-2) + '年' + month + '月' + date + '日(' + day + ')' + hh + ':' + mm;
      } else if (date_format === 7) {
        return month + '月' + date + '日 ' + hh + ':' + mm;
      } else if (date_format === 8) {
        return (year) + '-' + month + '-' + date;
      } else if (date_format === 9) {
        return month + '月' + date + '日(' + day + ')';
      }
    },
    getDay(num) {
      const w_day = ['日', '月', '火', '水', '木', '金', '土', '日'];
      return w_day[num];
    },
    getLastDateOfThisMonth(){
      //https://qiita.com/akase244/items/f558b9b3c51804103d16
      const dt = new Date()
      return new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
    },
    getNextSaturday(){
      return this._getNextDay(6)
    },
    getNextSunday(){
      return this._getNextDay(0)
    },
    _getNextDay(num){
      let obj_dt = new Date()
      for(let i = 0; i < 7; i++){
        if(obj_dt.getDay() === num){
          return obj_dt
        }
        obj_dt = this._getObjDate(this.subDate(this.getDate(obj_dt), -1))
      }
      return false
    },
    _getDateDiffFromNow(date){
      const now= new Date();
      const now_date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const obj_date = this._getObjDate(date)
      return (obj_date - now_date) / 86400000;
    },
    _dateForward(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const newDate = new Date(year, month, day + 1);
      return this.getDate(newDate);
    },
    subDate(date, n) {
      const objDate = new Date(date);
      const year = objDate.getFullYear();
      const month = objDate.getMonth();
      const day = objDate.getDate();
      const newDate = new Date(year, month, day - n);
      return this.getDate(newDate);
    },
    _dateBack(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const newDate = new Date(year, month, day - 1);
      return this.getDate(newDate);
    },
    ////////////////////////////////////////////////////////////
    //// ここまで日付操作関連のメソッド
    ////////////////////////////////////////////////////////////
  }
}
